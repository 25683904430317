*{
  margin: 0;
  padding: 0;
}
.container{
  background-color: black;
  height: 100vh;
  overflow: hidden;     /* scrollbar na aae */
  display: flex;
}
img{
  width: 30%;
}
.wrapper{
  background-color: white;
  width: 600px;
  height: 400px;
  margin: auto;      /* white box centre m lane k lie */
  display: flex;
  flex-direction: column;
 align-items: center;
 
}
h1{
  font-size: 40px;
  font-weight: 600;
  color:  #445A6F;
  margin: 40px 0;   /*top bottom 40   left right 0 */
}
button{
  width: 150px;
  height: 40px;
  border-radius: 50px;
  background-color: #445A6F;
  border: none;
  color: white;
  font-size: 20px;
  margin: 30px 0;
}